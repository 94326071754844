import React, { FC } from "react";
import { useParams, useSearchParams } from "react-router-dom";
import ChatBox from "../../components/Chat";


const Home: FC = () => {
  let { id } = useParams();
  const [searchParams] = useSearchParams();
  const theme = searchParams.get("theme");
  const apiKey = searchParams.get("apiKey") || process.env.REACT_APP_HOPPR_API_KEY;
  return (
    <div className={theme + " fullscreen-container"}>
      <ChatBox studyId={id} theme={theme} apiKey={apiKey}/>
    </div>
  );
};

export default Home;
