import Widget from "./src/index";
import {
  addResponseMessage,
  addUserMessage,
  setMsgLoaderVisible,
  deleteMessages,
  dropMessages,
} from "./src/store/dispatcher";
import React, { useState, useEffect, useRef } from "react";
import OpenAI, { toFile } from "openai";

import { v4 as uuidv4 } from "uuid";

import "./chat.css";
import MicIcon from "./icons/mic";
import TextMode from "./icons/text";

import { square, waveform } from "ldrs";
square.register();
waveform.register();

const ChatSideBar = (opts: { studyId: string, theme: string, apiKey: string }) => {
  const [chatEnabled, setChatEnabled] = useState(false);
  const [searchInputField, setSearchInputField] = useState("");

  const customLauncher = (launchChatMethod: any) => {
    setChatEnabled((prv) => {
      if (!prv) launchChatMethod();
      return true;
    });
  };
  const handleSearchInputChange = (e) => {
    setSearchInputField(e.target.value);
  };
  const handleNewUserMessage = (newMessage: any) => {
    sendMessage(newMessage.trim());
  };
  const { studyId, theme, apiKey } = opts;

  let title = "";

  /// DAN H'S DANGER ZONE

  let REACT_APP_OPENAI_KEY = process.env.REACT_APP_OPENAI_KEY;
  let REACT_APP_HOPPR_CONFIG = process.env.REACT_APP_HOPPR_CONFIG;
  let REACT_APP_HOPPR_API_URL = process.env.REACT_APP_HOPPR_API_URL;
  let REACT_APP_HOPPR_API_MODEL = process.env.REACT_APP_HOPPR_API_MODEL;

  const openai = new OpenAI({
    apiKey: REACT_APP_OPENAI_KEY,
    dangerouslyAllowBrowser: true,
  });
  const [recording, setRecording] = useState(false);
  const mediaRecorderRef = useRef(null);
  const [textBuffer, setTextBuffer] = useState("");
  const [visibleText, setVisibleText] = useState("");
  const firstSentence = useRef(null);
  const firstSentencePlayed = useRef(false);
  const [eosReceived, setEosReceived] = useState(true);
  const [sentences, setSentences] = useState([]);
  const [words, setWords] = useState([]);
  const [isSentenceComplete, setIsSentenceComplete] = useState([true, true]);
  const [messageId, setMessageId] = useState(null);
  const [config, setConfig] = useState(null);
  const [connected, setConnected] = useState(false);
  const [isDisabled, setIsDisabled] = useState(true);
  const hopprConversation = useRef([]);
  const [isHovered, setIsHovered] = useState(false);
  const [shouldAnimate, setShouldAnimate] = useState(true);

  useEffect(() => {
    const loadConfig = async () => {
      console.log("Loading config");
      const configResponse = await fetch(REACT_APP_HOPPR_CONFIG);
      let jsonConfig = await configResponse.json();
      setConfig(jsonConfig);
    };
    loadConfig();
    dropMessages();
  }, []);

  const setup = async () => {
    mediaRecorderRef.current = null;
    setRecording(false);
    setTextBuffer("");
    setVisibleText("");
    firstSentence.current = null;
    firstSentencePlayed.current = false;
    setEosReceived(true);
    setMessageId(null);
    setIsSentenceComplete([true, true]);
    setSentences([]);
    setWords([]);
    setConnected(false);
    await setupChat();
  };

  useEffect(() => {
    async function innerSetup() {
      await setup();
    }
    if (config == null) {
      return;
    }
    console.log("Config loaded. Starting chat experience.");
    innerSetup();
  }, [config]);
  // Observe sentences
  useEffect(() => {
    function playNext(sentence) {
      const text = sentence[0];
      const mp3 = sentence[1];
      if (shouldAnimate) {
        if (mp3 != null) {
          console.log("Starting audible sentence " + JSON.stringify(text));
          setIsSentenceComplete([false, false]);
          const audio = new Audio(mp3);
          audio.addEventListener(
            "ended",
            () => {
              console.log("Finished saying sentence " + JSON.stringify(text));
              setIsSentenceComplete((complete) => {
                if (!complete[1]) {
                  return [true, false];
                }
                if (complete[0]) {
                  return complete;
                }
                console.debug("Popping sentence in audio");
                setSentences((s) => s.filter((i) => i[0] !== text));
                return [true, true];
              });
            },
            { once: true }
          );
          audio.play();
        } else {
          console.log("Starting silent sentence " + JSON.stringify(text));
          setIsSentenceComplete([true, false]);
        }
        setWords((prev) => prev.concat(text.split(" ")));
      } else {
        console.log("Adding unanimated sentence.")
        setIsSentenceComplete([true, false]);
        setWords((prev) => prev.concat(text))
      }
    }
    console.debug("There are now " + sentences.length + " sentences");
    if (sentences.length === 0) {
      return;
    }
    setLoadingVisible(false);
    setIsSentenceComplete((complete) => {
      if (complete[0] && complete[1]) {
        playNext(sentences[0]);
      }
      return complete;
    });
  }, [sentences]);

  // Observe individual words
  useEffect(() => {
    function showNext(word) {
      console.debug("Adding word " + JSON.stringify(word));
      setVisibleText((prevMsg) => {
        const text = prevMsg + word + " ";
        setMessageId((id) => {
          if (id == null) {
            const newId = uuidv4().toString();
            addResponseMessage(text, newId);
            return newId;
          }
          deleteMessages(1, id);
          addResponseMessage(text, id);
          return id;
        });
        return text;
      });
      const timeout = word.endsWith(",") ? 600 : 220;
      setTimeout(() => {
        setWords((prev) => prev.slice(1));
      }, timeout);
    }
    console.debug("There are now " + words.length + " words");
    if (words.length === 0) {
      setIsSentenceComplete((complete) => {
        console.debug("Finished words for sentence");
        if (!complete[0]) {
          return [false, true];
        }
        if (complete[1]) {
          return complete;
        }
        console.debug("Popping sentence in words");
        setSentences((s) => s.slice(1));
        return [true, true];
      });
      return;
    }
    showNext(words[0].trim());
  }, [words]);

  const startRecording = async () => {
    //@ts-ignore
    setRecording(async (prevRecording) => {
      if (prevRecording) {
        return true;
      }
      console.log("Starting recording");
      const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
      const mediaRecorder = new MediaRecorder(stream);
      mediaRecorder.addEventListener(
        "dataavailable",
        handleDataAvailable
      );
      mediaRecorderRef.current = mediaRecorder;
      mediaRecorder.start();
      return true;
    });
  };

  const stopRecording = () => {
    setRecording(prevRecording => {
      if (!prevRecording) {
        return false;
      }
      const recorder = mediaRecorderRef.current
      if (recorder != null) {
        console.log("Stopping recording");
        recorder.stop();
        recorder.stream.getTracks().forEach((track) => track.stop());
        mediaRecorderRef.current = null;
      }
      return false;
    });
  };

  const handleDataAvailable = async ({ data }) => {
    if (data.size <= 10000) {
      console.warn(
        "Ignoring audio input because " +
          data.size +
          " bytes is less than the min threshold."
      );
      return;
    }
    console.log("Getting audio transcription");
    const buffer = await data.arrayBuffer();
    const transcription = await openai.audio.transcriptions.create({
      file: await toFile(buffer, "speech.mp3"),
      model: "whisper-1",
    });
    sendMessage(transcription.text);
  };

  const setLoadingVisible = (visible) => {
    console.log("Changing loading animation visible " + visible);
    setMsgLoaderVisible(visible);
  };

  const sendMessage = async (text) => {
    setShouldAnimate(true)
    setIsHovered(false)
    addUserMessage(text);
    setEosReceived(false);
    setSearchInputField("");
    setLoadingVisible(true);
    setMessageId(null);
    setTextBuffer("");
    setVisibleText("");
    firstSentence.current = null;
    firstSentencePlayed.current = false;

    await sendHopprMessage(text);
  };

  const sendHopprMessage = async (text) => {
    hopprConversation.current.push({ role: "user", content: text });
    const response = await fetch(`${REACT_APP_HOPPR_API_URL}/studies/${studyId}/chat`, {
      method: "POST",
      headers: { "Content-Type": "application/json", "x-api-key": apiKey },
      body: JSON.stringify({ model:  REACT_APP_HOPPR_API_MODEL, messages: hopprConversation.current }),
    });

    if (!response.body) throw new Error('ReadableStream not supported');

    const reader = response.body.getReader();
    const decoder = new TextDecoder();
    let message = "";
    while (true) {
      const { done, value } = await reader.read();
        if (done) break;
        const chunk = decoder.decode(value, { stream: true });

        const parts = chunk.split(/\r?\n/);
        for (const part of parts) {
          const text = part.replace(/^data: /, "")
          if (text.length > 0) {
            await appendResponse(text)
            if (text !== "<EOS>") {
              message += text
            }
          }
        }
    }
    hopprConversation.current.push({ role: "assistant", content: message });
  };

  const queueResponse = async (response, speak = true) => {
    if (response.trim().length === 0) {
      maybePlayFirstSentence();
      return;
    }
    const speech = speak ? await createSpeechMp3(response) : null;
    if (
      speak &&
      !firstSentencePlayed.current &&
      firstSentence.current == null
    ) {
      console.log("Delaying first sentence: " + JSON.stringify(response));
      firstSentence.current = [response, speech];
      setTimeout(() => {
        maybePlayFirstSentence();
      }, 2000);
      return;
    }
    maybePlayFirstSentence();
    setSentences((prev) => [...prev, [response, speech]]);
  };

  const maybePlayFirstSentence = () => {
    const sentence = firstSentence.current;
    if (sentence != null) {
      console.log("Enqueuing delayed first sentence.");
      setSentences((prev) => [...prev, sentence]);
      firstSentence.current = null;
      firstSentencePlayed.current = true;
    }
  };

  const appendResponse = async (text) => {
    if (text == null) {
      return;
    }
    //@ts-ignore
    setTextBuffer(async (prevText) => {
      if (text === "<EOS>") {
        console.log("Received EOS.");
        await queueResponse(await prevText);
        setEosReceived(true);
        return "";
      }
      const newText = (await prevText) + text;
      const trimmed = text.trim();
      const lastChar = trimmed[trimmed.length - 1];
      if (lastChar === "?" || lastChar === "!") {
        await addSentence(newText);
        return "";
      } else if (lastChar === ".") {
        const tokens = newText.split(" ");
        const lastToken = tokens[tokens.length - 1];
        console.debug("Last token: " + lastToken + " in " + newText);
        //@ts-ignore
        if (
          lastToken.length <= 1 ||
          //@ts-ignore
          isNaN(lastToken.substring(0, lastToken.length - 1))
        ) {
          await addSentence(newText);
          return "";
        }
      }
      return newText;
    });
  };

  const addSentence = async (sentence) => {
    console.log("Adding sentence: " + JSON.stringify(sentence));
    await queueResponse(sentence);
  };

  const createSpeechMp3 = async (text) => {
    const mp3 = await openai.audio.speech.create({
      model: "tts-1",
      voice: "alloy",
      input: text,
    });
    const buffer = await mp3.arrayBuffer();
    return URL.createObjectURL(new Blob([buffer]));
  };

  const setupChat = async () => {
    setConnected(true);
    await queueResponse(
      config["welcomeMessage"]
        .replace("{botName}", config["botName"])
        .replace("{modality}", "radiology"),
      false
    );
  };

  useEffect(() => {
    setIsDisabled(
      !connected ||
        !eosReceived ||
        sentences.length > 0 ||
        firstSentence.current != null
    );
  }, [connected, sentences, eosReceived, firstSentence]);

  const toggleIsHovered = (hovered) => {
    setIsHovered(hovered);
  }

  const stopAnimating = () => {
    console.log("Stopping response animation.")
    setShouldAnimate(false)
  }

  const applyTheme = (classname) => {
    return theme + " " + classname
  }
  /// END OF DAN H'S DANGER ZONE

  return (
    <div className={applyTheme("chatSidebar")}>
      <Widget
        title={title}
        subtitle={""}
        handleNewUserMessage={handleNewUserMessage}
        emojis={false}
        launcher={customLauncher}
      />
      <div className={applyTheme("inputPanel")}>
        {!isDisabled && (
          <div className={applyTheme("sendPanel")}>
            <button
              className="arbitrary micButton"
              onMouseDown={startRecording}
              onMouseUp={stopRecording}
              onMouseLeave={stopRecording}
            >
              <MicIcon size={"30px"} color={recording ? "red" : "white"} />
            </button>
            <form
              onSubmit={(e) => {
                e.preventDefault();
                handleNewUserMessage(searchInputField);
              }}
            >
              <input
                className={applyTheme("textEntryComponent")}
                onChange={handleSearchInputChange}
                value={searchInputField}
              />
            </form>
            <button
              className={applyTheme("arbitrary sendButton")}
              disabled={searchInputField.trim().length === 0}
              onClick={() => {
                handleNewUserMessage(searchInputField);
              }}
            >
              <TextMode size={"30px"} />
            </button>
          </div>
        )}
        {isDisabled && (
          <div className={applyTheme("loadingContainer")}>
            <div onMouseEnter={() => toggleIsHovered(true)} onMouseLeave={() => toggleIsHovered(false)} onClick={stopAnimating}>
              {(isHovered || !shouldAnimate) && (
                <l-square size="32" speed="1.3" color="white" />
              )}
              {(!isHovered && shouldAnimate) && (
                <l-waveform size="32" speed="1.3" color="white" />
              )}
            </div>
          </div>
        )}
        {config && (
          <div
            className="disclaimer"
            dangerouslySetInnerHTML={{ __html: config["disclaimerText"] }}
          />
        )}
      </div>
    </div>
  );
};

export default ChatSideBar;
